import { isEmpty, isEqual } from 'lodash-es';
import store from 'store';
import { ACTIVITY_TYPES, DEFAULT_FILTERS } from 'state-domains/constants';
import { FilterObject, FilterOperator, HeaderType, SystemFilter } from 'state-domains/domain';

export const CACHED_FILTERS_STORE = 'cached_filters';

export function getFiltersForDrillhole(
    id: string,
    userId: string,
    subscriptionId: string,
    type: ACTIVITY_TYPES,
) {
    const allFilters = store.get(`${CACHED_FILTERS_STORE}_${userId}_${subscriptionId}`, {});
    if (!!allFilters && allFilters[type] && !!id) {
        return allFilters[type][id] ?? DEFAULT_FILTERS();
    }
    return DEFAULT_FILTERS();
}

export function getFiltersFromStorage(
    userId: string,
    subscriptionId: string,
    moduleKey: string,
    filterKey: string,
) {
    const allFilters = store.get(`${CACHED_FILTERS_STORE}_${userId}_${subscriptionId}`, {});
    if (!!allFilters && allFilters[moduleKey]?.[filterKey]) {
        return allFilters[moduleKey]?.[filterKey];
    }
    return null;
}

export function updateStorageFilters(
    id: string,
    userId: string,
    subscriptionId: string,
    newFilters: any,
    type: any,
) {
    const allFilters = store.get(`${CACHED_FILTERS_STORE}_${userId}_${subscriptionId}`, {});
    const dHoleFilters = allFilters[type] ?? {};
    const updated = {
        ...allFilters,
        [type]: { ...dHoleFilters, [id]: newFilters },
    };
    store.set(`${CACHED_FILTERS_STORE}_${userId}_${subscriptionId}`, { ...updated });
}

export function isFilterSet(
    id: string,
    userId: string,
    subscriptionId: string,
    type: ACTIVITY_TYPES,
) {
    const filters = getFiltersForDrillhole(id, userId, subscriptionId, type);
    return !isEqual(filters, DEFAULT_FILTERS());
}

export function isFilterInLocalStorage(
    userId: string,
    subscriptionId: string,
    moduleKey: string,
    filterKey: string,
) {
    const filters = getFiltersFromStorage(userId, subscriptionId, moduleKey, filterKey);
    return !isEmpty(filters);
}

export const findUserFilterInfo = (
    filters: FilterObject[],
    systemFilters: SystemFilter[],
    headerTypes: Record<string, HeaderType>,
    filterOperators: Record<string, FilterOperator>,
) =>
    filters.map((filter: FilterObject) => {
        let userFilter: any = {
            type: filter.type,
            selectedValues: filter.values,
        };

        const filterOperator = filterOperators[filter.type];

        if (filterOperator?.hasOperator) {
            userFilter = {
                ...userFilter,
                operator: filterOperator.operators.find(
                    (operator) => operator.name === filter.operator,
                ),
            };
        }

        if (filter.system_filter) {
            const systemFilter = systemFilters.find((x) => x.name === filter.system_filter);

            if (systemFilter) {
                userFilter = {
                    ...userFilter,
                    name: filter.system_filter,
                    label: systemFilter.label,
                    id: systemFilter.id,
                    ...(systemFilter.hasOperator && {
                        operator: systemFilter.operators?.find((x) => x.name === filter.operator),
                    }),
                };
            }
        } else if (filter.field) {
            const headerType = headerTypes[filter.field];

            if (headerType) {
                userFilter = {
                    ...userFilter,
                    name: headerType.name,
                    label: headerType.name,
                    id: headerType.id,
                    dynamicFilter: true,
                };
            }
        }

        return userFilter;
    });
